import React, { useState, useEffect, useRef } from 'react';
import '../styles/sudoku.css';
import '../styles.css'


function Sudoku() {
  const emptyArray = Array(9).fill().map(() => Array(9).fill(''));
  const startingNotes = decompressNotes(emptyArray);
  const [ig, setIg] = useState([])
  const [board, setBoard] = useState([]);

  const [notes, setNotes] = useState(startingNotes);

  const [selectedCell, setSelectedCell] = useState(null); // Selected cell as an object
  const [selectedCells, setSelectedCells] = useState([]); // Multi-cell selection
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const inputRefs = useRef([]); // Create refs to track the inputs
  const gridRef = useRef(null);

  const [notesToggled, setNotesToggled] = useState(false);
  
  useEffect(() => {
    const boardData = localStorage.getItem('boardData');
    const igData = localStorage.getItem('igData');
    const notesData = localStorage.getItem('notesData');

    console.log(startingNotes)
    if (boardData) {
      console.log(JSON.parse(boardData))
      setBoard(JSON.parse(boardData));
      setIg(JSON.parse(igData));
      if (notesData) {
        setNotes(JSON.parse(notesData))
      }
      setIsLoading(false)
    } else {
      fetch("https://puzzlehavenback-production.up.railway.app/generate-initial-grid")
      .then(response => response.json())
      .then(data => {
        setBoard(decompressSudoku(data.ig))
        setIg(decompressSudoku(data.ig))

        localStorage.setItem('boardData', JSON.stringify(decompressSudoku(data.ig))); 
        localStorage.setItem('igData', JSON.stringify(decompressSudoku(data.ig)));
      })
      .finally(() => {
        setIsLoading(false);
      });
      console.log('fetched')
    }
  }, []);

  // Focus the selected cell whenever selectedCell state changes
  useEffect(() => {
    if (selectedCell !== null) {
      const { row, col } = selectedCell;
      const inputToFocus = inputRefs.current[`${row}-${col}`];
      if (inputToFocus) {
        inputToFocus.focus(); // Move focus to the selected cell
      }
    }
  }, [selectedCell]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedCell !== null) {
        let { row, col } = selectedCell;
  
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (row > 0) row -= 1;
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (row < 8) row += 1;
          break;
        case 'ArrowLeft':
          event.preventDefault();
          if (col > 0) col -= 1;
          break;
        case 'ArrowRight':
          event.preventDefault();
          if (col < 8) col += 1;
          break;
        default:
          return; // Do nothing for other keys
      }
  
      setSelectedCell({ row, col }); // Update the selected cell
      setSelectedCells([{ row, col }])
      }
    };
  
    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCell, selectedCells]); // Dependency array, re-runs if selectedCell changes
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Ensure selectedCells is not empty
      if (selectedCells.length === 0) return;
    
      let value;
      if (e.keyCode >= 49 && e.keyCode <= 57) {  // Numbers 1-9 (main number row)
        value = e.keyCode - 48; // Convert keyCode to actual number
      } else if (e.keyCode >= 97 && e.keyCode <= 105) { // numpad
        value = e.keyCode - 96;
      } else if (e.key === 'Backspace') {
        value = 'Backspace';
      }
    
      // Apply value to all selected cells
      if (value) {
        selectedCells.forEach(({ row, col }) => {
          handleChange(row, col, value);
        });
      }
    
      // Handle toggling notes mode
      if (e.key === 'Shift') {
        setNotesToggled(true); 
      }
    };
  
    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setNotesToggled(false);
      }
    };
  
    // Add event listeners for keydown and keyup
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  
    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [selectedCell, notesToggled, selectedCells]);

  const handleChange = (row, col, value) => {
    const newBoard = [...board];
    const newNotes = [...notes];
  
    // Ensure that the cell is not an initial (pre-filled) cell
    if (ig[row][col] === '') {
      if (notesToggled) {
        let cellNotes = newNotes[row][col] || [];
  
        // Validate if the input is a valid digit between 1 and 9
        if (/^[1-9]$/.test(value)) {
          let integerValue = parseInt(value, 10);
  
          const updatedNotes = cellNotes.includes(integerValue)
            ? cellNotes.filter(note => note !== integerValue) // Remove note if it already exists
            : [...cellNotes, integerValue]; // Add note if it doesn't exist
  
          updatedNotes.sort((a, b) => a - b);
          newNotes[row][col] = updatedNotes.length ? updatedNotes : "";
        }
      } else {
        // Handle input for regular digits (when not in notes mode)
        if (/^[1-9]$/.test(value)) {
          let integerValue = parseInt(value, 10);
          newBoard[row][col] = integerValue;
        } else if (value === 'Backspace') {
          // Clear the cell on the board
          newBoard[row][col] = "";
        }
        checkBoard();
        newNotes[row][col] = "";
      }
  
      // Update the state and localStorage after handling the change
      setNotes(newNotes);
      localStorage.setItem('notesData', JSON.stringify(newNotes));
      setBoard(newBoard);
      localStorage.setItem('boardData', JSON.stringify(newBoard));
    }
  };

  const handleMouseDown = (row, col) => {
    setIsMouseDown(true);
    setSelectedCell({ row, col });
    setSelectedCells([{ row, col}])
  };

  const handleMouseMove = (row, col) => {
    if (isMouseDown) {
      // Add the new cell to the selection if dragging
      setSelectedCells((prevSelected) => {
        // Avoid duplicating the same cell
        const alreadySelected = prevSelected.some(
          (cell) => cell.row === row && cell.col === col
        );
        if (!alreadySelected) {
          return [...prevSelected, { row, col }];
        }
        return prevSelected;
      });
    }
  };

  const handleClickOutside = (event) => {
    if (gridRef.current && !gridRef.current.contains(event.target)) {
      setSelectedCell(null); 
      setSelectedCells([]);
    }
  };

  useEffect(() => {
    // Add click event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  // useEffect(() => {
  //   console.log(selectedCell);
  //   if (selectedCell) {
  //     console.log(notes[selectedCell.row][selectedCell.col])
  //   }
  // }, [selectedCell])

  const resetBoard = () => {
    const freshBoard = JSON.parse(localStorage.getItem('igData')); 
    setBoard(JSON.parse(localStorage.getItem('igData'))); 
    localStorage.setItem('boardData', JSON.stringify(freshBoard)); 
    localStorage.setItem('igData', JSON.stringify(freshBoard));
  };

  const checkBoard = () => {
    const finalBoard = board; // Use the current state of the board

    // Helper function to check if the board is fully filled (no empty cells)
    const isBoardFilled = (board) => {
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (board[row][col] === '') return false;
        }
      }
      return true;
    };

    // Check if all cells are filled first
    if (!isBoardFilled(finalBoard)) {
      console.log('Board is not yet fully filled. No need to check for validity yet.');
      return; // Exit early if the board is not completely filled
    }

    const isValidRow = (row) => {
      const set = new Set();
      for (let num of row) {
        if (set.has(num)) return { valid: false, reason: `Row contains duplicate number: ${num}` };
        set.add(num);
      }
      return { valid: true };
    };

    const isValidColumn = (board, colIndex) => {
      const set = new Set();
      for (let row = 0; row < 9; row++) {
        const num = board[row][colIndex];
        if (set.has(num)) return { valid: false, reason: `Column ${colIndex + 1} contains duplicate number: ${num}` };
        set.add(num);
      }
      return { valid: true };
    };

    const isValidSubgrid = (board, rowStart, colStart) => {
      const set = new Set();
      for (let row = rowStart; row < rowStart + 3; row++) {
        for (let col = colStart; col < colStart + 3; col++) {
          const num = board[row][col];
          if (set.has(num)) return { valid: false, reason: `Subgrid starting at (${rowStart + 1}, ${colStart + 1}) contains duplicate number: ${num}` };
          set.add(num);
        }
      }
      return { valid: true };
    };

    // Now perform the validation checks if the board is completely filled
    for (let row = 0; row < 9; row++) {
      const rowCheck = isValidRow(finalBoard[row]);
      if (!rowCheck.valid) {
        alert(`Row ${row + 1} is invalid: ${rowCheck.reason}`);
        return;
      }
    }

    for (let col = 0; col < 9; col++) {
      const colCheck = isValidColumn(finalBoard, col);
      if (!colCheck.valid) {
        alert(`Column ${col + 1} is invalid: ${colCheck.reason}`);
        return;
      }
    }

    for (let row = 0; row < 9; row += 3) {
      for (let col = 0; col < 9; col += 3) {
        const subgridCheck = isValidSubgrid(finalBoard, row, col);
        if (!subgridCheck.valid) {
          alert(`Subgrid is invalid: ${subgridCheck.reason}`);
          return;
        }
      }
    }

    alert('The Sudoku grid is valid and complete!');
    setSelectedCell(null)
    setSelectedCells([])
};


  function decompressSudoku(compressedSudoku) {
    let board = [];
    let currentRow = [];
    let i = 0;

    while (i < compressedSudoku.length) {
      if (compressedSudoku[i] === '-') {
        i++;
        let emptyCount = parseInt(compressedSudoku[i], 10);
        for (let j = 0; j < emptyCount; j++) {
          currentRow.push('');
          if (currentRow.length === 9) {
            board.push([...currentRow]);
            currentRow = [];
          }
        }
      } else {
        currentRow.push(parseInt(compressedSudoku[i], 10));
      }
      i++;
      if (currentRow.length === 9) {
        board.push([...currentRow]);
        currentRow = [];
      }
    }
    return board;
  }

  function decompressNotes(compressedNotes) {
    let board = []; // Final 9x9 grid
    let currentRow = []; // Current row being processed
    let i = 0;

    while (i < compressedNotes.length) {
        if (compressedNotes[i] === '-') {
            i++; // Move past the '-'
            let emptyCount = parseInt(compressedNotes[i], 10); // Read how many empty cells to skip
            i++; 

            for (let j = 0; j < emptyCount; j++) {
                currentRow.push(''); // Push empty cells
                if (currentRow.length === 9) {
                    board.push([...currentRow]); 
                    currentRow = [];
                }
            }
        } else {
            // Handle notes
            let notesArray = '';
            while (i < compressedNotes.length && compressedNotes[i] !== '-') {
                notesArray += compressedNotes[i]; 
                i++;
            }

            const notes = notesArray.includes(',')
                ? notesArray.split(',').map(Number)  
                : [Number(notesArray)];

            currentRow.push(notes);

            if (currentRow.length === 9) {
                board.push([...currentRow]);
                currentRow = [];
            }
        }
    }

    return board; // Return the full board
  }

  function playAnother(difficulty) {
    console.log('finding new');
    fetch("https://puzzlehavenback-production.up.railway.app/generate-initial-grid", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ difficulty }),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data)

        // Set the initial board state using data from api
        setBoard(decompressSudoku(data.ig))
        setIg(decompressSudoku(data.ig))
        setNotes(emptyArray)

        localStorage.setItem('notesData', JSON.stringify(emptyArray));
        localStorage.setItem('boardData', JSON.stringify(decompressSudoku(data.ig))); 
        localStorage.setItem('igData', JSON.stringify(decompressSudoku(data.ig)));
      })
  }

  if (isLoading || ig === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className='screen' id='gameScreen'>
      <div className='logo'>
        <span className='puzzle'>Puzzle</span>
        <span className='haven'>Haven</span>
      </div>
      <div
        className="sudokuGrid"
        tabIndex="0"
        onMouseUp={handleMouseUp}
        ref={gridRef}
      >
        {board.map((row, rowIndex) =>
          row.map((cell, colIndex) => {

            const cellNotes = notes[rowIndex][colIndex]; // code for mapping cellnotes

            let isHighlightedRow = false;
            let isHighlightedCol = false;
            let isHighlightedSubgrid = false;
            let isHighlightedSameDigit = false;

            if (selectedCell !== null && selectedCells.length < 2) {
              isHighlightedRow = rowIndex === selectedCell.row;

              isHighlightedCol = colIndex === selectedCell.col;

              const subgridRowStart = Math.floor(selectedCell.row / 3) * 3;
              const subgridColStart = Math.floor(selectedCell.col / 3) * 3;
              isHighlightedSubgrid =
                rowIndex >= subgridRowStart && rowIndex < subgridRowStart + 3 &&
                colIndex >= subgridColStart && colIndex < subgridColStart + 3;
              
              // Check if the cell contains the same number as the selected cell
              const selectedValue = board[selectedCell.row][selectedCell.col];
              if (selectedValue && cell === selectedValue) {
                isHighlightedSameDigit = true;
              }
            }

            return (
              <div className="sudokuCellWrapper" key={`${rowIndex}-${colIndex}`}>
                <input
                  key={`${rowIndex}-${colIndex}`}
                  ref={(cell) => (inputRefs.current[`${rowIndex}-${colIndex}`] = cell)}
                  value={cell}
                  // onChange={(e) => handleChange(rowIndex, colIndex, e.target.value)}
                  maxLength={1}
                  className={`sudokuCell 
                    ${isHighlightedRow ? 'highlight-row' : ''} 
                    ${isHighlightedCol ? 'highlight-col' : ''} 
                    ${isHighlightedSubgrid ? 'highlight-subgrid' : ''}
                    ${isHighlightedSameDigit ? 'highlight-same-digit' : ''}
                    ${selectedCells.some(cell => cell.row === rowIndex && cell.col === colIndex) ? 'highlighted' : ''}`}
                  data-x={colIndex}
                  data-y={rowIndex}
                  // onClick={() => handleClick(rowIndex, colIndex)}
                  onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                  onFocus={(e) => e.target.blur()}
                  onMouseMove={() => handleMouseMove(rowIndex, colIndex)}
                  data-initial={ig[rowIndex][colIndex] !== ''}
                  readOnly={ig[rowIndex][colIndex] !== ''}
                />
                
                {cell === '' && cellNotes && Array.isArray(cellNotes) && (
                  <div className="notes">
                    {cellNotes.map((note, i) => (
                      <span key={i} className="note">
                        {note}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        )}

      </div>
      <div className="buttons">
        <div className="functionButton" onClick={resetBoard}>Reset Grid</div>
        {/* <div className="functionButton" onClick={checkBoard}>Check Grid</div> */}
        {/* <div className="functionButton" onClick={toggleNotes}>Toggle Notes</div> */}
        {/* {notesToggled ? (
          <div>Toggled</div>
        ) : (
          <div>Not toggled</div>
        )} */}
      </div>
      <div className="headers">Play A New Puzzle</div>
      <div className="buttons" id='playAgainButtons'>
          <div className="functionButton" onClick={() => playAnother('easy')}>Easy</div>
          <div className="functionButton" onClick={() => playAnother('medium')}>Medium</div>
          <div className="functionButton" onClick={() => playAnother('hard')}>Hard</div>
          <div className="functionButton" onClick={() => playAnother('insane')}>Insane</div>
        </div>
    </div>
  );
}

export default Sudoku;
