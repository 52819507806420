import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Start from './pages/Start'; // Start page component
import Sudoku from './pages/Sudoku';   // Play page component
import WordSearch from './pages/WordSearch';

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Start />} />
        <Route path="/start" element={<Start />} />
        <Route path="/sudoku" element={<Sudoku />} />
        <Route path="/wordsearch" element={<WordSearch />} />

      </Routes>
    </Router>
  );
}

export default App;