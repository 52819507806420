import React from 'react'
import '../styles.css'
import '../styles/start.css'

function Start() {
  return (
    <div className='screen' id='startScreen'>
      <div className='logo'>
        <span className='puzzle'>Puzzle</span>
        <span className='haven'>Haven</span>
      </div>
    </div>
  )
}

export default Start